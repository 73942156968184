/**
 * Created by gschulz on 23.05.16.
 */
var Redirector = (function () {

    var _private = {
        instance:  this,
        options:   {
            skip:   false,
            delay:  3000,
            title:  'Redirect ...',
            target: null
        },
        timeoutId: 0
    };

    this.redirect = function (options) {
        options = $.extend(true, {}, _private.options, options);
        if (!_private.validateOptions(options)) {
            return false;
        }
        if (!options.delay) {
            location.href = options.target;
            return;
        }
        _private.openDialog(options);
        _private.timeoutId = setTimeout(function () {
            location.href = options.target;
        }, options.delay);
    };


    _private.validateOptions = function (options) {
        return !(!_.isString(options.target) || !_.isString(options.title) || typeof options.delay !== 'number');
    };

    _private.openDialog = function (options) {
        var dialogOptions = {
            modal:           true,
            isConfirmDialog: true,
            dialogClass:     'redirector',
            closeOutside:    false,
            html:            _private.getDialogContent(options)
        };
        if (options.skip) {
            dialogOptions.buttons = {
                'button.cancel': function () {
                    clearTimeout(_private.timeoutId);
                    $(this).dialog("close");
                }
            };
        }
        var dialogContainer = new EfbHelperDialog(dialogOptions).show();
        dialogContainer.find('div.loader-wrap').find('div').animate({'width': '100%'}, options.delay, 'linear');
    };

    _private.getDialogContent = function (options) {
        var container = $('<div/>', {'class': ''});
        container.append($('<p/>', {'class': 'bold'}).html(options.title));
        container.append($('<div/>', {'class': 'loader-wrap'}).append($('<div/>').css('width', '0%')));
        return container;
    };

});